'use client'

import { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useAuth from '@crystal-eyes/hooks/useAuth'
import useEditProfile from '@meetings/app/p/[slug]/hooks/useEditProfile'
import useVerifiedStatus from '@crystal-eyes/hooks/profiles/context/useVerifiedStatus'
import useIsOwnProfile from '@crystal-eyes/hooks/profiles/context/useIsOwnProfile'
import useCanDeleteProfile from '@crystal-eyes/hooks/profiles/context/useCanDeleteProfile'
import logger from '@crystal-eyes/utils/logger'
import SecondaryNavigation from './SecondaryNavigation'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { identify, track } from '@meetings/lib/analytics_v2'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import useProfileId from '@crystal-eyes/hooks/profiles/useProfileId'
import usePersonality from '@crystal-eyes/hooks/profiles/usePersonality'
import usePersonalInfo from '@crystal-eyes/hooks/profiles/usePersonalInfo'
import useRelationship from '@crystal-eyes/hooks/profiles/context/useRelationship'
import SecondaryNavigationLink from './SecondaryNavigationLink'
import cn from 'classnames'

export default function ProfileSubNav() {
  const [mounted, setMounted] = useState(false)
  const pathname = usePathname()
  const slug = pathname?.replace('/p/', '')
  const identity = { id: `snap:${slug}` }
  const { authed, data: authDetails } = useAuth()
  const { data: me } = useMeV2()
  const { data: profileId } = useProfileId({ identity: identity! })
  const { data: personality } = usePersonality({ identity })
  const { data: personalInfo } = usePersonalInfo({ identity })
  const { data: verifiedStatus } = useVerifiedStatus({ identity })
  const { data: isOwnProfile } = useIsOwnProfile({ identity })
  const { data: canDeleteProfile, deleteProfile } = useCanDeleteProfile({
    identity,
  })
  const { data: relationship } = useRelationship({ identity })

  useEffect(() => {
    if (authed && me && profileId) {
      if (relationship?.isCandidate) {
        track('Candidate Profile Viewed', {
          candidate_id: profileId,
          candidate_disc: personality?.discType || '',
        })
      } else if (relationship?.isCoworker) {
        track('Team Profile Viewed', {
          profile_id: profileId,
          profile_name: personalInfo?.fullName || '',
        })
      } else {
        track('Customer Profile Viewed', { custom_id: profileId })
      }
      const primaryEmail = me?.emails?.[0]?.value
      if (authDetails?.jwtPayload?.sub && primaryEmail)
        identify(
          authDetails?.jwtPayload?.sub.split(':')[1],
          {
            date_of_last_dashboard_login: Date.now(),
            hubspot_organization_id: '',
            firstName: authDetails?.jwtPayload?.name?.split(' ')[0] || '',
            lastName: authDetails?.jwtPayload?.name?.split(' ')[1] || '',
            email: primaryEmail,
          },
          { groupId: '' },
        )
    }
    if (!isOwnProfile) {
      track('Dashboard Profile Viewed')
    }
  }, [identity, authed, profileId, personality, authDetails])

  useEffect(() => {
    setMounted(true)
  }, [])

  const {
    editProfileState: { setState: setEditProfile },
  } = useEditProfile({ identity })

  const allowEdits = false

  if (!mounted) return null
  return (
    <SecondaryNavigation>
      {authed && (
        <div
          className={cn(
            'utilities',
            'tw-flex',
            'tw-flex-col',
            'tw-mt-2',
            'tw-gap-2',
            'tw-pt-2',
            'tw-pb-2',
            'tw-pl-2',
            'tw-w-[calc(100%-38px)]',
            'tw-border-t',
            'tw-border-white',
          )}
        >
          {canDeleteProfile && (
            <SecondaryNavigationLink
              handleClick={() => {
                if (
                  confirm(
                    'Are you sure you want to permanently delete this profile?',
                  )
                ) {
                  deleteProfile()
                    .then(() => (window.location.href = '/app'))
                    .catch(err => {
                      logger.error('Delete Profile Error', err)

                      alert('Something went wrong deleting the profile!')
                    })
                }
              }}
              icon={Icons.Trash}
              label="Delete"
            />
          )}
          {allowEdits && (
            <SecondaryNavigationLink
              handleClick={() => setEditProfile('edit-profile')}
              icon={Icons.Edit}
              label="Edit"
            />
          )}
        </div>
      )}
    </SecondaryNavigation>
  )
}
