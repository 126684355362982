export const publicPaths = [
  '/api/.*',
  '/login',
  '/logout',
  '/okta',
  '/why-crystal/.*',
  '/tos',
  '/sign-up',
  '/404',
  '/_error',
  '/new-assessment',
  '/ce/installed',
  '/ce/uninstalled',
  '/onboarding',
  '/password',
  '/reactivate',
  '/register',
  '/slack/installed',
  '/slack-app',
  '/upgrade',
  '/user-survey',
  '/favicon.ico',
  '/static/.*',
]
