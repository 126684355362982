'use client'

import { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import cn from 'classnames'

export default function NavLogoSection({ expanded }: { expanded: boolean }) {
  const homePath = '/home'
  const [renderLogos, setRenderLogos] = useState<boolean>(false)
  useEffect(() => {
    setRenderLogos(true)
  }, [])

  const shouldRenderCrystalLogo = renderLogos
  return (
    <>
      {!renderLogos && <div className={cn('tw-w-[74px]')}></div>}

      {shouldRenderCrystalLogo && (
        <div className={cn({ 'tw-pl-2': expanded })}>
          <Link className={cn('hover:tw-no-underline')} href={homePath}>
            {expanded ? (
              <div
                className={cn('tw-text-white', 'tw-text-4xl', 'tw-font-bold')}
              >
                Meetings
              </div>
            ) : (
              <div>
                <Image
                  src="/static/share/images/crystal_svg_logo_white_mini.svg"
                  alt="Crystal Logo"
                  width={37}
                  height={37}
                />
              </div>
            )}
          </Link>
        </div>
      )}
    </>
  )
}
