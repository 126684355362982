'use client'
import logger from '@crystal-eyes/utils/logger'
import { pathnameHelper } from '@meetings/lib/analytics'
import { RUDDER_STACK_WRITE_KEY } from '@meetings/lib/constants'
import { useEffect } from 'react'

async function rudderInitialize() {
  window.rudderanalytics = await import('rudder-sdk-js')
  window.rudderanalytics.load(
    RUDDER_STACK_WRITE_KEY,
    'https://crystalknows-dataplane.rudderstack.com',
    {
      integrations: { All: true }, // load call options
    },
  )
  window.rudderanalytics.ready(() => {
    logger.info('Rudderstack Initialized')
  })
  window.rudderanalytics.page(
    undefined,
    pathnameHelper(window.location.pathname),
    {
      path: window.location.pathname,
      search: window.location.search,
      title: document.title,
      url: window.location.href,
    },
  )
}

export default function RudderStack() {
  useEffect(() => {
    if (typeof window !== 'undefined' && !window.rudderanalytics) {
      rudderInitialize()
    }
  }, [])
  return null
}
