'use client'

import { useEffect, useState } from 'react'
import { useRouter, usePathname } from 'next/navigation'
import type { NavSection, NavSectionTitle } from './types'
import Icon from '@crystal-eyes/components/elements/Icon/Icon'
import { getCookie } from '@crystal-eyes/lib/browser'
import { chrome_extension_installed_cookie } from '@meetings/lib/utils/browser'
import cn from 'classnames'

interface OwnProps {
  section: NavSection
  toggleActiveSection: (section: NavSectionTitle) => void
  expanded?: boolean
  external?: boolean
}

export default function NavTopSection({
  section,
  toggleActiveSection,
  expanded,
  external,
}: OwnProps) {
  const [hasCEInstalled, setHasCEInstalled] = useState<boolean | null>(null)
  const router = useRouter()
  const pathName = usePathname()

  useEffect(() => {
    setHasCEInstalled(!!getCookie(chrome_extension_installed_cookie))
  }, [])

  const headerClick = () => {
    if (section.path && external) {
      window && window.open(section.path, '_blank')
    } else if (section.path) {
      router.push(section.path)
    } else {
      toggleActiveSection(section.title)
    }
  }

  if (
    section.title === 'Install Chrome Extension' &&
    (hasCEInstalled === null || hasCEInstalled)
  ) {
    return null
  }
  return (
    <div
      className={cn(
        'navSection',
        'tw-flex',
        'tw-flex-col',
        'tw-gap-[1px]',
        'tw-whitespace-nowrap',
      )}
      id={section.title}
    >
      <div
        className={cn(
          'navTitle',
          'tw-flex',
          'tw-items-center',
          'tw-gap-9',
          'tw-cursor-pointer',
          'tw-text-sm',
          'tw-p-2',
          'tw-leading-[140%]',
          'tw-rounded-tl-4',
          'tw-rounded-bl-4',
          {
            'tw-gap-4': expanded,
            'tw-bg-sidebar-active-item':
              section.path && pathName === section.path,
            'tw-text-sidebar': external,
            'tw-text-sidebar-strong': !external,
          },
          'hover:tw-bg-sidebar-active-item',
        )}
        onClick={headerClick}
      >
        <Icon
          className={cn(
            'navIcon',
            'tw-h-[15px]',
            'tw-w-[15px]',
            'tw-stroke-2',
            'tw-text-blue-500',
            {
              'tw-text-sidebar': external,
              'tw-text-sidebar-strong': !external,
            },
          )}
          icon={section.icon}
        />
        {section.title}
      </div>
    </div>
  )
}
