import Link from 'next/link'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import cn from 'classnames'

interface OwnProps {
  handleClick: () => void
  label: string
  icon: Icons
}

export default function SecondaryNavigationLink({
  handleClick,
  icon,
  label,
}: OwnProps) {
  return (
    <div
      className={cn(
        'tw-flex',
        'tw-gap-2',
        'tw-text-base',
        'tw-font-normal',
        'tw-leading-[140%]',
        'tw-px-1',
        'tw-py-0',
        'tw-text-sidebar',
        'tw-items-center',
        'tw-cursor-pointer',
        'hover:tw-text-primary',
      )}
      onClick={e => {
        e.preventDefault()
        handleClick()
      }}
    >
      <Icon
        className={cn('tw-h-4', 'tw-w-4', 'tw-text-sidebar-strong')}
        icon={icon}
      />{' '}
      {label}
    </div>
  )
}
