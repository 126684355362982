'use client'

import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { publicPaths } from '@meetings/lib/public_paths'

export default function CheckAuthPages({
  authed = false,
}: {
  authed?: boolean
}) {
  if (authed) return null
  const Router = useRouter()
  const checkAuth = useCallback(() => {
    const isPublicPath = publicPaths.some(path =>
      window.location.pathname.match(new RegExp(path.replace(/\[.*\]/, '.*'))),
    )
    if (isPublicPath) return
    Router.push(`/login?unauthenticated=true`)
  }, [])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  return null
}
