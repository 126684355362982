import Axios from 'axios'
import { v3ApiUrl } from '@crystal-eyes/config'
import { appName, localAuthToken, sessionToken } from './user'
import { FullAuthUser } from '@meetings/lib/types'

export interface AuthedHeaders {
  authToken: string
  sessionToken: string
  appName?: string
}

export type APIResponse<T> = { data: T } & Record<string, unknown>

interface AuthMeResponse {
  user: FullAuthUser
  refreshed_token: string
  paragon_token: string
}

export const PublicApi = () =>
  Axios.create({
    baseURL: `${v3ApiUrl}/v3`,
  })

export const Api = (headers?: AuthedHeaders) =>
  Axios.create({
    baseURL: `${v3ApiUrl}/v3`,
    headers: {
      Authorization: `Bearer ${headers?.authToken ?? localAuthToken()}`,
      Session: headers?.sessionToken ?? sessionToken(),
      App: headers?.appName ?? appName(),
    },
  })

export function loadMeRequest(headers?: AuthedHeaders) {
  return Api(headers).get<APIResponse<AuthMeResponse>>('me')
}
